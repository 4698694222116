import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authRequired: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      authRequired: false
    },
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/uren',
    name: 'hour-logging',
    meta: {
      authRequired: true
    },
    component: () => import(/* webpackChunkName: "hour-logging" */ '../views/HourLogging.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const user = () => {
  const auth = getAuth()
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (userFirebase) => {
      unsubscribe()
      resolve(userFirebase)
    }, reject)
  })
}

router.beforeEach(async (to, from, next) => {
  console.log('route change')
  const usr = await user()
  if (to.matched.some(record => record.meta.authRequired)) {
    if (usr) {
      console.log('ingelogd')
      next()
    } else {
      console.log('naar login')
      next({
        path: '/login'
      })
    }
  } else {
    if (usr) {
      console.log('niet auth required en heeft user')
      next({
        path: '/'
      })
    } else {
      console.log('niet auth required GEEN user')
      next()
    }
  }
})

export default router

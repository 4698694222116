<template>
  <div class="home">
    <site-header />
    <div class="planning p-6">
      <header class="flex items-center justify-center mb-6">
        <button @click="updateCurrentWeek(-1)" class="transform rotate-180">
          <icon name="arrow-right" class="text-gray-400 text-xl" />
        </button>
        <span class="text-2xl font-medium mx-4">
          {{ this.weekStart }} - {{ this.weekEnd }}
        </span>
        <button @click="updateCurrentWeek(1)" class="">
          <icon name="arrow-right" class="text-gray-400 text-xl" />
        </button>
      </header>
      <div class="content-container bg-white px-4 pb-6 rounded-xl">
        <div class="flex">
          <div class="w-full">
            <ul class="dates-container">
              <li v-for="day in weekDays" :key="day.date" class="dates py-6">
                <div class="flex flex-col justify-center items-center">
                  <span class="text-gray-400 font-medium mb-1">
                    {{ day.dayName }}
                  </span>
                  <span class="text-3xl font-bold">
                    {{ day.day }}
                  </span>
                </div>
              </li>
            </ul>
            <ul v-for="user in users" :key="user.name">
              <li class="names flex items-center justify-center border-t">
                <div class="relative w-14 h-14">
                  <img :src="require(`@/assets/img/${user.image}`)" class="absolute left-0 top-0 w-full h-full object-cover rounded-full">
                </div>
              </li>
              <li
                v-for="(day, i) in weekDays"
                :key="`day-${day.date}`"
                class="item border-t p-3 flex flex-col justify-center items-center"
                :class="{ 'is-weekend': i > 4 }"
              >
                <single-day
                  :tasks="day.events"
                  :user-id="user.id"
                  :date="day.date"
                  @open-modal="showModal" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="isModalVisible"
      @close="closeModal"
    >
      <template v-slot:header>
        Taak
      </template>

      <template v-slot:body>
        <create-task
          :task="task"
          :projects="projects"
          :categories="categories"
          @close="closeModal"
        />
      </template>
    </modal>
  </div>
</template>

<script>

import db from '@/firebase'
import { collection, query, where, onSnapshot } from 'firebase/firestore'
import dayjs from 'dayjs'
import axios from 'axios'

import SiteHeader from '@/components/SiteHeader.vue'
import CreateTask from '@/components/CreateTask'
import Modal from '@/components/Modal.vue'
import SingleDay from '@/components/SingleDay.vue'
const weekday = require('dayjs/plugin/weekday')
const weekOfYear = require('dayjs/plugin/weekOfYear')
require('dayjs/locale/nl')
dayjs.locale('nl')
dayjs.extend(weekday)
dayjs.extend(weekOfYear)
dayjs.Ls.en.weekStart = 1

let unsubscribe = null

export default {
  name: 'Home',
  components: {
    CreateTask,
    Modal,
    SingleDay,
    SiteHeader
  },
  data () {
    return {
      isModalVisible: false,
      currentIndex: 0,
      task: {
        title: '',
        userId: '',
        start: '',
        description: ''
      },
      weekDays: [],
      weekStart: '',
      weekEnd: '',
      categories: [],
      projects: [],
      users: [
        {
          id: 'WPALlrVt4iVcVJXwDsvNdxWTSLB3',
          name: 'Erwin',
          image: 'erwin.jpg'
        },
        {
          id: 'p3FCtWK3rhPlMJQyOhyGgYHt8KR2',
          name: 'Samuela',
          image: 'samuela.jpg'
        },
        {
          id: 'P76XK51XhZMhA7DqvJGofFsnDJW2',
          name: 'GJ',
          image: 'gj.jpg'
        },
        {
          id: 'FMH6dxi83xQMkCfc8TyNyVJawE83',
          name: 'Daphne',
          image: 'daphne.jpg'
        },
        {
          id: 'j2Ol6zwMuyfqSvqBeZzxLu3wlDO2',
          name: 'Lydia',
          image: 'lydia.jpg'
        },
        {
          id: 'Uh7T78gbATS0ZZ16O4VuEdnbBRt2',
          name: 'Denise',
          image: 'denise.jpg'
        }
      ]
    }
  },
  mounted () {
    this.getData()
    this.getProjectsFromActiveCollab()
    console.log(this.getWeekDays(this.currentIndex))
    this.weekDays = this.getWeekDays(this.currentIndex)
  },
  computed: {
    selectedWeekNumber () {
      const currentWeekStart = dayjs().startOf('week').format('YYYY-MM-DD')
      return dayjs(currentWeekStart).add(this.currentIndex, 'week').week()
    }
  },
  methods: {
    updateCurrentWeek (direction) {
      unsubscribe()
      this.currentIndex = this.currentIndex + direction
      this.weekDays = this.getWeekDays(this.currentIndex)
      this.getData()
    },
    async getProjectsFromActiveCollab () {
      const { data } = await axios.get('https://planning.zwrtwt.nl/api')
      // const { data } = await axios.get('http://zwit-planner-api.test')
      this.projects = data.projects
      this.categories = data.categories
    },
    getData () {
      const q = query(collection(db, 'Event'), where('weekNumber', '==', this.selectedWeekNumber))
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        this.weekDays.forEach((day) => {
          day.events = []
        })
        querySnapshot.forEach((doc) => {
          const i = this.weekDays.findIndex(day => day.date === doc.data().start)
          if (i === -1) {
            return
          }
          this.weekDays[i].events.push({
            id: doc.id,
            ...doc.data()
          })
        })
      })
    },
    getWeekDays (index) {
      const currentWeekStart = dayjs().startOf('week').format('YYYY-MM-DD')
      const date = dayjs(currentWeekStart).add(index, 'week').format('YYYY-MM-DD')
      this.weekStart = dayjs(date).startOf('week').format('MMM D')
      this.weekEnd = dayjs(date).endOf('week').format('MMM D')
      return [...Array(7)].map((day, i) => {
        return {
          day: dayjs(date).add(i, 'day').format('D'),
          date: dayjs(date).add(i, 'day').format('YYYY-MM-DD'),
          dayName: dayjs(date).add(i, 'day').format('dddd'),
          weekNumber: dayjs('2022-02-02').add(i, 'day').week(),
          events: []
        }
      })
    },
    showModal (task) {
      console.log(task)
      this.task = {
        id: task.id || '',
        title: task.title || '',
        titleAlt: task.titleAlt || '',
        user_id: task.user_id,
        start: task.start,
        description: task.description || '',
        duration: task.duration || ''
      }
      this.isModalVisible = true
    },
    closeModal () {
      this.isModalVisible = false
    }
  }
}
</script>

<style lang="scss">

.planning {
  .content-container {
    width: 1500px;
    margin: 0 auto;
    box-shadow: 0 11px 7px rgba(0,0,0,0.015);
  }

  .site-header {
    box-shadow: 0 11px 7px rgba(0,0,0,0.015);
  }

  ul {
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;
  }

  .item {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
    min-height: 100px;
    max-width: 200px;
    overflow: hidden;
    height: 200px;

    &.is-weekend {
      background: repeating-linear-gradient(
        -45deg,
        #FAFAFA,
        #FAFAFA 2px,
        white 2px,
        white 10px
      );
    }
  }

  .dates {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 200px;
  }

  .dates-container {
    margin-left: 80px;
  }

  span {
    display: block;
  }

  .names {
    width: 80px;
  }

  .task + .task {
    @apply mt-2;
  }

  header {
    button {
      line-height: 0;
    }
  }
}

</style>

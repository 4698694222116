// import { firebase } from 'firebase/app'
// import 'firebase/firestore'

// const firebaseConfig = {
//   apiKey: 'AIzaSyDzBhmadYfybrY6Hresk05OvHXJGkSF3fM',
//   authDomain: 'zwit-planner.firebaseapp.com',
//   projectId: 'zwit-planner',
//   storageBucket: 'zwit-planner.appspot.com',
//   messagingSenderId: '261905919841',
//   appId: '1:261905919841:web:df1fe78de2e8ee2bc19f20'
// }

// firebase.initializeApp(firebaseConfig)

// export default firebase.firestore()

import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
initializeApp({
  apiKey: 'AIzaSyDzBhmadYfybrY6Hresk05OvHXJGkSF3fM',
  authDomain: 'zwit-planner.firebaseapp.com',
  projectId: 'zwit-planner',
  storageBucket: 'zwit-planner.appspot.com',
  messagingSenderId: '261905919841',
  appId: '1:261905919841:web:df1fe78de2e8ee2bc19f20'
})

const db = getFirestore()

export default db

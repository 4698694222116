<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span :class="classes" class="svg-icon" v-html="url" />
</template>

<script>

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    classes () {
      const classes = [`icon-${this.name}`]
      return classes.join(' ')
    },
    url () {
      try {
        return require(`@/assets/img/${this.name}.svg?inline`)
      } catch (e) {
        return `<small class='icon-error'><i title='Cannot find file: ${this.name}.svg'>error &times</i></small>`
      }
    }
  }
}

</script>

<style lang="scss">

  .svg-icon {
    display: inline-block;

    svg {
      width: 1em;
      height: 1em;
      display: block;
      color: currentColor;
    }

    svg path {
      stroke: none;
      fill: currentColor;
    }

    .icon-error {
      font-size: 0.75em;
      color: red;
      border: 1px solid;
      padding: 5px;
      cursor: help;
    }

  }

</style>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal relative rounded-2xl bg-white p-6"
        role="dialog"
      >
        <header class="modal-header mb-4">
          <h2 class="text-2xl font-semibold">
            <slot name="header">This is the default tile!</slot>
          </h2>
          <button type="button" class="absolute right-0 top-0 p-4" @click="close" aria-label="Close modal">
            <icon name="close" class="text-black text-sm" />
          </button>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="body">This is the default body!</slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 400px;
  overflow-x: auto;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
</style>

<template>
  <header class="site-header bg-white h-16 flex items-center justify-between px-6 mb-12">
    <div class="flex items-center">
      <img :src="require('@/assets/img/logo-icon.png')" class="w-8 h-auto mr-2">
      Planner
    </div>
    <ul class="flex">
      <li class="px-6">
        <router-link to="/">
          Planning
        </router-link>
      </li>
      <li>
        <router-link to="/uren">
          Uren
        </router-link>
      </li>
    </ul>
    <div class="flex">
      <div class="flex items-center justify-center border-r px-4">
        <span class="ml-3 text-center font-semibold">
          {{ user.name }}
        </span>
      </div>
      <div class="flex items-center pl-4">
        <button @click="logout" class="text-gray-400 font-semibold underline">
          Uitloggen
        </button>
      </div>
    </div>
  </header>
</template>

<script>

import { getAuth, signOut } from 'firebase/auth'

export default {
  data () {
    return {
      users: [
        {
          id: 'WPALlrVt4iVcVJXwDsvNdxWTSLB3',
          name: 'Erwin',
          image: 'erwin.jpg'
        },
        {
          id: 'p3FCtWK3rhPlMJQyOhyGgYHt8KR2',
          name: 'Samuela',
          image: 'samuela.jpg'
        },
        {
          id: 'P76XK51XhZMhA7DqvJGofFsnDJW2',
          name: 'GJ',
          image: 'gj.jpg'
        },
        {
          id: 'FMH6dxi83xQMkCfc8TyNyVJawE83',
          name: 'Daphne',
          image: 'daphne.jpg'
        },
        {
          id: 'j2Ol6zwMuyfqSvqBeZzxLu3wlDO2',
          name: 'Lydia',
          image: 'lydia.jpg'
        },
        {
          id: 'Uh7T78gbATS0ZZ16O4VuEdnbBRt2',
          name: 'Denise',
          image: 'denise.jpg'
        }
      ]
    }
  },
  computed: {
    user () {
      const auth = getAuth()
      return this.users.find(user => auth.currentUser.uid === user.id)
    }
  },
  methods: {
    logout () {
      const auth = getAuth()
      signOut(auth).then(() => {
        console.log('uitgelogd')
        this.$router.push('/login')
      }).catch((error) => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.site-header {
  box-shadow: 0 11px 7px rgba(0,0,0,0.015);
}

li a {
  display: inline-block;
  padding: 6px 0;
}

.router-link-exact-active {
  position: relative;
  font-weight: 600;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background: black;
    bottom: 0;
    left: 0;
  }
}
</style>

<template>
  <ul v-if="userTasks.length" class="w-full flex flex-1 flex-col">
    <li
      v-for="event in userTasks"
      :key="`event-${event.start}`"
      class="task relative rounded text-white p-3 text-xs cursor-pointer whitespace-normal overflow-hidden"
      :class="event.duration === 'half' ? 'h-full' : 'h-full'"
      :style="{ backgroundColor: hexToRgbA(event.color), color: event.color }"
      @click="showModal(event)"
    >
      <span class="text-black text-sm font-semibold">
        {{ event.titleAlt }}
      </span>
      <span v-if="event.title" class="text-gray-500 text-xs block">
        {{ event.title }}
      </span>
    </li>
  </ul>
  <div v-if="showAddButton" class="flex-1 flex items-center">
    <button
      @click="showModal({ start: date, user_id: userId })"
      class="flex justify-center items-center w-8 h-8 rounded-full bg-gray-300 text-white text-2xl font-bold hover:bg-black transition"
    >
      +
    </button>
  </div>
</template>

<script>

export default {
  props: {
    tasks: {
      type: Array,
      required: true,
      default: () => []
    },
    userId: {
      type: String,
      required: true,
      default: ''
    },
    date: {
      type: String,
      required: true,
      default: ''
    }
  },
  emits: ['open-modal'],
  computed: {
    showAddButton () {
      return this.userTasks.length < 2 && (!this.userTasks.filter(item => item.duration === 'full').length)
    },
    userTasks () {
      return this.tasks.filter(task => task.user_id === this.userId)
    }
  },
  methods: {
    showModal (task) {
      this.$emit('open-modal', task)
    },
    hexToRgbA (hex) {
      let c = ''
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = '0x' + c.join('')
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.2)'
      }
      return '#F7F7F7'
    }
  }
}

</script>

<style lang="scss">

.task {

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    background-color: currentColor;
  }
}

</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/css/tailwind.css'
import '@vueform/multiselect/themes/default.css'
import SvgIcon from '@/components/SvgIcon.vue'

const app = createApp(App).use(router)

app.component('icon', SvgIcon)

app.mount('#app')

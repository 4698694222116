<template>
  <div>
    <div class="input-container">
      <div class="items-center" :class="{ 'flex': !isEditing }">
        <div class="input-container" :class="{ 'mr-12': !isEditing }">
          <div class="input-label">
            Datum
          </div>
          <Datepicker
            v-if="isEditing"
            v-model="selectedTask.start"
            format="yyyy-MM-dd"
          />
          <div v-else class="font-medium">
            {{ selectedTask.start }}
          </div>
        </div>
        <div class="input-container">
          <label class="input-label">
            Duur
          </label>
          <div v-if="isEditing" class="flex">
            <div class="flex">
              <input type="radio" id="full" value="full" v-model="selectedTask.duration">
              <label class="ml-2" for="full">Hele dag</label>
            </div>
            <div class="flex ml-3">
              <input type="radio" id="half" value="half" v-model="selectedTask.duration">
              <label class="ml-2" for="half">Halve dag</label>
            </div>
          </div>
          <div v-else class="font-medium">
            {{ getDurationLabel(selectedTask.duration) }}
          </div>
        </div>
      </div>
      <span class="input-label">
        Project
      </span>
      <Multiselect
        v-if="isEditing"
        v-model="selectedProject"
        :options="projects"
        value-prop="title"
        :object="true"
        track-by="title"
        label="title"
        placeholder="Geen project geselecteerd"
      />
      <span v-else class="font-medium">
        <span v-if="selectedProject.title">
          {{ selectedProject.title }}:
        </span>
        {{ selectedTask.titleAlt }}
      </span>
    </div>
    <div v-if="isEditing" class="input-container">
      <label class="input-label">
        Taak
      </label>
      <input
        type="text"
        v-model="selectedTask.titleAlt"
      />
    </div>
    <div class="input-container">
      <label class="input-label">
        Beschrijving (optioneel)
      </label>
      <textarea
        v-if="isEditing"
        type="text"
        v-model="selectedTask.description"
      />
      <span v-else class="font-medium">
        {{ selectedTask.description || '-' }}
      </span>
    </div>
    <div class="flex mt-6 justify-end">
      <button v-if="task.id" type="text" @click="deleteTask" class="button-round ml-3">
        <icon name="delete" class="text-black text-2xl" />
      </button>
      <button v-if="task.id" type="text" @click="toggleEditMode" class="button-round ml-3">
        <icon name="edit" class="text-black text-2xl" />
      </button>
      <button v-if="isEditing" type="text" class="button ml-3" @click="handleTask">
        {{ submitLabel }}
      </button>
    </div>
  </div>
</template>

<script>

import db from '@/firebase'
import { collection, addDoc, setDoc, doc, deleteDoc } from 'firebase/firestore'
import Multiselect from '@vueform/multiselect'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import dayjs from 'dayjs'
require('dayjs/locale/nl')
dayjs.locale('nl')

const weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export default {
  components: {
    Datepicker,
    Multiselect
  },
  props: {
    task: {
      type: Object,
      default: () => {},
      required: true
    },
    projects: {
      type: Array,
      default: () => [],
      required: true
    },
    categories: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data () {
    return {
      selectedTask: this.task,
      selectedProject: '',
      isEditing: false
    }
  },
  created () {
    if (!this.task.id) {
      this.isEditing = true
    }
  },
  mounted () {
    if (this.projectIsFromActiveCollab) {
      this.selectedProject = this.projects.find(project => project.title === this.task.title)
    }
  },
  computed: {
    submitLabel () {
      return this.task.id ? 'Aanpassen' : 'Toevoegen'
    },
    projectIsFromActiveCollab () {
      return this.projects.map(project => project.title).includes(this.task.title)
    },
    category () {
      if (this.selectedProject === null) {
        return undefined
      }
      return this.categories.find(cat => cat.id === this.selectedProject.catId)
    },
    color () {
      if (this.category === undefined) {
        return '#ACACAC'
      }
      return this.category.color
    }
  },
  methods: {
    getDurationLabel (duration) {
      return duration === 'half' ? 'Halve dag' : 'Hele dag'
    },
    toggleEditMode () {
      this.isEditing = !this.isEditing
    },
    handleTask () {
      this.task.id ? this.updateTask() : this.createTask()
    },
    async createTask () {
      await addDoc(collection(db, 'Event'), {
        title: this.selectedProject.title ? this.selectedProject.title : '',
        titleAlt: this.selectedTask.titleAlt,
        description: this.selectedTask.description,
        start: dayjs(this.selectedTask.start).format('YYYY-MM-DD'),
        weekNumber: dayjs(this.selectedTask.start).week(),
        duration: this.selectedTask.duration,
        color: this.color,
        user_id: this.task.user_id
      })
      this.$emit('close')
    },
    async updateTask () {
      await setDoc(doc(db, 'Event', this.task.id), {
        title: this.selectedProject !== null && this.selectedProject.title ? this.selectedProject.title : '',
        titleAlt: this.selectedTask.titleAlt,
        description: this.selectedTask.description,
        start: dayjs(this.selectedTask.start).format('YYYY-MM-DD'),
        weekNumber: dayjs(this.selectedTask.start).week(),
        duration: this.selectedTask.duration,
        color: this.color,
        user_id: this.task.user_id
      })
      this.$emit('close')
    },
    async deleteTask () {
      await deleteDoc(doc(db, 'Event', this.task.id))
      this.$emit('close')
    }
  }
}

</script>

<style scoped lang="scss">

</style>
